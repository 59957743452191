import React, { forwardRef } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type IconButtonProps } from '@atlaskit/button/new';
import type { TriggerProps } from '@atlaskit/popup/types';

import { ListItem } from '../../components/list-item';

import { IconButton } from './themed/migration';

export interface UserActionProps
	extends Partial<Pick<TriggerProps, 'aria-controls' | 'aria-expanded' | 'aria-haspopup'>> {
	/**
	 * The label for the button
	 */
	label: React.ReactNode;
	icon: IconButtonProps['icon'];
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	/**
	 * Allows preloading popup components
	 */
	onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
	isSelected?: boolean;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
}

/**
 * __User action__
 *
 * A user action button for the top navigation. Used for adding custom actions that are not already provided.
 *
 * For common actions, like `Notifications`, `Help`, `Profile`, `Settings`, use the provided components as appropriate.
 */
export const UserAction = forwardRef<HTMLButtonElement, UserActionProps>(
	(
		{
			label,
			icon,
			onClick,
			onMouseEnter,
			isSelected,
			testId,
			interactionName,
			'aria-controls': ariaControls,
			'aria-expanded': ariaExpanded,
			'aria-haspopup': ariaHasPopup,
		}: UserActionProps,
		forwardedRef,
	) => (
		<ListItem>
			<IconButton
				ref={forwardedRef}
				icon={icon}
				label={label}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				appearance="subtle"
				isSelected={isSelected}
				isTooltipDisabled={false}
				testId={testId}
				interactionName={interactionName}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				aria-haspopup={ariaHasPopup}
			/>
		</ListItem>
	),
);
