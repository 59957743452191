/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useState } from 'react';

import { cx, jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { useLayoutEffect } from '@atlaskit/ds-lib/use-layout-effect';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Popup from '@atlaskit/popup';
import { UNSAFE_useMediaQuery as useMediaQuery } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';

import { List } from '../../components/list';

import { HasCustomThemeContext } from './themed/has-custom-theme-context';
import { IconButton } from './themed/migration';

const containerStyles = cssMap({
	root: {
		gridColumn: 3,
		display: 'flex',
		justifyContent: 'end',
		// Used for SSR to keep space for the dropdown to be rendered into
		minWidth: '32px',
	},
});

const listStyles = cssMap({
	root: {
		alignItems: 'center',
		gap: token('space.050'),
		display: 'flex',
	},
	hideOnSmallViewport: {
		// Hiding on small viewports (needed for SSR)
		// Once JS ticks in on the client, the list won't be rendered.
		display: 'none',
		'@media (min-width: 48rem)': {
			display: 'flex',
		},
	},
	popupContainer: {
		padding: token('space.100'),
	},
});

/**
 * __User actions__
 *
 * Wrapper for the top navigation actions on the inline-end (right) side of the top navigation.
 */
export const UserActions = ({
	children,
	label = 'Actions',
	showMoreButtonLabel = 'Show more',
}: {
	children: React.ReactNode;
	label?: string;
	showMoreButtonLabel?: string;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	// Always setting to `false` for the initial render (will flip in an effect for mobile)
	const [isMobile, setIsMobile] = useState<boolean>(false);
	// Note: `query` is a stable value between renders
	const query = useMediaQuery('below.sm', function onChange(event) {
		setIsMobile(event.matches);
		// Any time we shift between mobile and desktop
		// We always close the popup
		setIsOpen(false);
	});

	useLayoutEffect(() => {
		// The default is `false`, and desktop will remain as `false` (no re-render).
		// Mobile will flip from `false` to `true`.
		setIsMobile(query?.matches ?? false);
	}, [query]);

	return (
		<nav aria-label={label} css={containerStyles.root}>
			{isMobile ? (
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-start"
					shouldRenderToParent
					content={() => (
						<HasCustomThemeContext.Provider value={false}>
							<List xcss={cx(listStyles.root, listStyles.popupContainer)}>{children}</List>
						</HasCustomThemeContext.Provider>
					)}
					trigger={(triggerProps) => (
						<IconButton
							{...triggerProps}
							label={showMoreButtonLabel}
							isSelected={isOpen}
							onClick={() => setIsOpen(!isOpen)}
							icon={ShowMoreHorizontalIcon}
							isTooltipDisabled={false}
						/>
					)}
				/>
			) : (
				<List xcss={cx(listStyles.root, listStyles.hideOnSmallViewport)}>{children}</List>
			)}
		</nav>
	);
};
