/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { type ComponentType, forwardRef } from 'react';

import { jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import NotificationsIcon from '@atlaskit/icon/core/notification';

import { BadgeContainer } from '../../components/badge-container';

import { IconButton } from './themed/migration';

const NOTIFICATIONS_BADGE_ID = 'atlassian-navigation-notification-count';

type NotificationsProps = {
	/* The component to render as the badge */
	badge: ComponentType;

	/* Whether the notifications button is selected (highlights the button when the notifications menu is open) */
	isSelected?: boolean;

	/* The label for the notifications button */
	label: React.ReactNode;

	/* The click handler for the notifications button (expected to open the notifications menu) */
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;

	testId?: string;

	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __Notifications__
 *
 * The notifications button in the top navigation bar.
 */
export const Notifications = forwardRef<HTMLButtonElement, NotificationsProps>(
	({ badge, onClick, label, isSelected, testId, interactionName }, forwardedRef) => (
		<BadgeContainer id={NOTIFICATIONS_BADGE_ID} badge={badge} role="listitem">
			<IconButton
				icon={NotificationsIcon}
				label={label}
				onClick={onClick}
				appearance="subtle"
				isSelected={isSelected}
				ref={forwardedRef}
				isTooltipDisabled={false}
				testId={testId}
				interactionName={interactionName}
			/>
		</BadgeContainer>
	),
);
