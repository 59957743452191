/* eslint-disable @repo/internal/dom-events/no-unsafe-event-listeners */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { cssMap, jsx } from '@atlaskit/css';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { useSortedSkipLinks } from '../../context/skip-links/skip-links-data-context';
import { type SkipLinkData } from '../../context/skip-links/types';

import { SkipLink } from './skip-link';

// TODO (BLU-3215): Fix avoid using ts-ignore
const styles = cssMap({
	root: {
		margin: token('space.250'),
		paddingBlock: token('space.100'),
		paddingInline: token('space.075'),
		position: 'fixed',
		zIndex: -1,
		backgroundColor: token('elevation.surface.overlay'),
		borderRadius: token('border.radius'),
		boxShadow: token('elevation.shadow.overlay'),
		// @ts-ignore - should be beyond the screen
		insetInlineStart: -999999,
		opacity: 0,
		transform: 'translateY(-50%)',
		'&:focus-within': {
			// @ts-ignore - should be over any other element
			zIndex: 'calc(infinity)',
			insetInlineStart: token('space.0'),
			opacity: 1,
			transform: 'translateY(0%)',
		},
	},
	skipLinkList: {
		listStylePosition: 'outside',
		listStyleType: 'none',
		marginBlockStart: token('space.050'),
		paddingInlineStart: token('space.0'),
	},
});

// Correctly typed handler for use with native DOM events
const handleBlur = (event: Event) => {
	if (!(event.target instanceof Element)) {
		return;
	}
	event.target.removeAttribute('tabindex');
	event.target.removeEventListener('blur', handleBlur);
};

const escapeHandler = (event: KeyboardEvent) => {
	if (event.key === 'Escape') {
		const activeElement = document.activeElement as HTMLElement;
		if (activeElement !== null) {
			activeElement.blur();
		}
	}
};

const attachEscHandler = () => window.addEventListener('keydown', escapeHandler, false);
const removeEscHandler = () => window.removeEventListener('keydown', escapeHandler, false);

/**
 * A container element for the skip links
 * The default label will be used when the `skipLinksLabel` attribute is not
 * provided or the attribute is an empty string. If a string comprised only of
 * spaces is provided, the skip link heading element will be removed, but the
 * default label will still be used in `title` attribute of the skip links
 * themselves.
 */
export const SkipLinksContainer = ({ label }: { label: string }) => {
	const sortedSkipLinks = useSortedSkipLinks();
	if (sortedSkipLinks.length === 0) {
		return null;
	}
	const emptyLabelOverride = !!label?.match(/^\s+$/);
	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onFocus={attachEscHandler} onBlur={removeEscHandler} css={[styles.root]}>
			{emptyLabelOverride ? null : <Text weight="medium">{label}</Text>}
			<ol css={styles.skipLinkList}>
				{sortedSkipLinks.map(({ id, label }: SkipLinkData) => (
					<SkipLink key={id} href={`#${id}`} isFocusable>
						{label}
					</SkipLink>
				))}
			</ol>
		</div>
	);
};
