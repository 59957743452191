/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useContext } from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

import { HomeActionsAttachRef } from '../../context/home-actions/home-actions-context';

const styles = cssMap({
	root: {
		// Taking up the full height of top bar to allow for monitoring mouse events, for improving the side nav flyout experience
		height: '100%',
		alignItems: 'center',
		display: 'flex',
		gap: token('space.050'),
		gridColumn: 1,
		'@media (min-width: 30rem)': {
			paddingInlineEnd: token('space.075'),
		},
	},
});

/**
 * __Home actions__
 *
 * Wrapper for the top navigation actions on the inline-start (left) side of the top navigation.
 */
export const HomeActions = ({
	children,
	testId,
}: {
	children: React.ReactNode;
	testId?: string;
}) => {
	const ref = useContext(HomeActionsAttachRef);

	return (
		<div css={styles.root} ref={ref} data-testid={testId}>
			{children}
		</div>
	);
};
