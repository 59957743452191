/**
 * @jsxRuntime classic
 */
import React, { type ComponentType, forwardRef } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import HelpIcon from '@atlaskit/icon/core/question-circle';

import { BadgeContainer } from '../../components/badge-container';
import { ListItem } from '../../components/list-item';

import { IconButton } from './themed/migration';

const HELP_NOTIFICATION_BADGE_ID = 'atlassian-navigation-help-notification-count';

type HelpProps = {
	/* The component to render as the badge */
	badge?: ComponentType;

	/* Whether the help button is selected (highlights the button when the help panel is open) */
	isSelected?: boolean;

	/* The label for the help button */
	label: React.ReactNode;

	/* The click handler for the help button (expected to open the help panel) */
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;

	testId?: string;

	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __Help__
 *
 * The help button for the top navigation.
 */
export const Help = forwardRef<HTMLButtonElement, HelpProps>(
	({ badge, onClick, label, isSelected, testId, interactionName }: HelpProps, forwardedRef) => {
		const button = (
			<IconButton
				icon={HelpIcon}
				label={label}
				onClick={onClick}
				appearance="subtle"
				isSelected={isSelected}
				ref={forwardedRef}
				isTooltipDisabled={false}
				testId={testId}
				interactionName={interactionName}
			/>
		);

		if (!badge) {
			return <ListItem>{button}</ListItem>;
		}

		return (
			<BadgeContainer id={HELP_NOTIFICATION_BADGE_ID} badge={badge} role="listitem">
				{button}
			</BadgeContainer>
		);
	},
);
