/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	root: {
		display: 'flex',
		gap: token('space.100'),
		width: '100%',
		gridColumn: 2,
		justifyContent: 'end',
		'@media (min-width: 30rem)': {
			justifyContent: 'center',
		},
	},
});

/**
 * __Common actions__
 *
 * Wrapper for the actions in the middle of the top navigation.
 */
export const CommonActions = ({ children }: { children: React.ReactNode }) => (
	<div css={styles.root}>{children}</div>
);
